

































































































































































































































































































































































































































import Vue from 'vue';
import 'bootstrap-vue';
import Multiselect from 'vue-multiselect';
import Component from 'vue-class-component';
import VueElementLoading from "vue-element-loading";
import {Ax} from '@/utils';
import i18nService from "@/services/i18n";
import store from "@/services/store";
import CBudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import CBudgetAgreementModal from '@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal.vue';
import CBudgetAgreementHistModal from '@/modules/budget-request/components/budget-sum-calc/budget-agreement-hist-modal.vue';
import BipCard from '@/modules/budget/bip/bip-card.vue';
import BipTest from '@/modules/budget/bip/bip-test.vue';
import BipLink from '@/modules/budget/bip/bip-link.vue';
import BipValues from '@/modules/budget/bip/bip-values.vue';
import BipModel from '@/modules/budget/bip/bip-model.vue';
import {
    BipProjectDataClass,
    DataType,
    RowNewFinanceData,
    IGu,
    makeToast,
    years,
    sortByField,
    findItem,
    getRowKey,
    checkAccess,
    checkGu,
    checkGuReg,
    filterVersion,
    defineBip,
    postData,
    forInitiator,
    forRecipient,
    getNumber,
    versionByRegion,
    setCurVariant, setVariants, checkFinCosts, nameLocale, itemNameLocale,
    getCommonTitle, getBipTitle, getFilterTitle, getTFieldTitle, getCardTitle, getSmsTitle
} from './bip-types';
import {loadVariants} from "@/modules/budget/budgetVariantService";

// декоратор @Component указывает, что класс — это компонент Vue
@Component({
    name: 'bip-form',
    components: {
        'multiselect': Multiselect,
        'c-budg-form-lst': CBudgetFormsList,
        'c-budg-agr-modal': CBudgetAgreementModal,
        'c-budg-agr-hist-modal': CBudgetAgreementHistModal,
        'loading': VueElementLoading,
        BipCard,
        BipModel,
        BipTest,
        BipLink,
        BipValues
    }
})

export default class BipForm extends Vue {
    $refs!: {
        bipCard: HTMLFormElement;
        bipList: HTMLFormElement;
        drop: HTMLFormElement;
        bcPlanYear: HTMLFormElement;
        bcRegionBudget: HTMLFormElement;
        bcDataType: HTMLFormElement;
        bcVersion: HTMLFormElement;
        bcAbp: HTMLFormElement;
        bcPrg: HTMLFormElement;
        bcPpr: HTMLFormElement;
        refAgrModal: HTMLFormElement;
        refHistModal: HTMLFormElement;
    };
    private curFormSelect = '/bip/bip-form';
    private mode_code = 'bip'

    private expTypes = [
        { value: 0, name_ru: "Переходящие проекты", name_kk: "Ауыспалы жобалар", name_en: "Transition projects" },
        { value: 1, name_ru: "Новые инициативы", name_kk: "Жаңа бастамалар", name_en: "New initiatives" }
    ];

    private openFilterWindow = false;
    private backList = true;
    private showForm = false;
    private showTest = false;
    private saving = false;
    private updateMark = false;
    private host = true;
    private check = false;
    private loading = false;
    private bipForm: any;

    private pBar = 0;

    private planPeriod: any = null;
    private selRegionBudget: any = null;
    private selDataType: any = null;
    private selVersion: any = null;
    private selAbp: any = null;
    private selPrg: any = null;
    private selPpr: any = null;

    private regionBudgetList: any[] = [];
    private dataTypeList: DataType[] = [];
    private versionList: any[] = [];
    private regionList: any[] = [];
    private directionList: any[] = [];
    private objectList: any[] = [];
    private statusList: any[] = [];
    private gpList: any[] = [];
    private spfList: any[] = [];
    private bipCifList: any [] = [];
    private criteriaValues: Map<any, any> = new Map();
    private bipLinkCriterias: any [] = [];
    private bipLinkTypes: any [] = [];
    private dictUnit: any [] = [];

    private bipList: any[] = [];
    private prevList: any[] = [];
    private bipProjectData: BipProjectDataClass | null = null;

    private levels: any = [];
    private regLevels: any = [];
    private ebkMap: Map<any, any> = new Map();
    private abpBase: any[] = [];
    private regAbpBase: any[] = [];

    private agrMap = new Map();
    private agrBtnLst = {back: [], forward: []};
    private agreeList: any[] = [];

    private userLevel = 0;
    private operationCode: any[] = [];
    private userAbps: any [] = [];
    private userGus: any [] = [];

    private location: any = {};

    public created() {
        console.log(new Date().toISOString(), 'form created');

        this.$watch("planPeriod", (value) => {
            if (value) {
                console.log(new Date().toISOString(), 'form planPeriod = ', value);
                setCurVariant(this);
            }
        });
        this.$watch('selRegionBudget', (value) => {
            if (value) {
                console.log(new Date().toISOString(), 'form selRegionBudget = ', value);
                setCurVariant(this);
            }
        });
        this.$watch('selDataType', (value) => {
            if (value) {
                console.log(new Date().toISOString(), 'form selDataType = ', value);
                setCurVariant(this, true);
            }
        });
        this.$watch('selVersion', (value) => {
            this.selAbp = null;
            this.bipList = [];

            if (value && !this.loading) {
                console.log(new Date().toISOString(), 'form selVersion', this.selVersion);
                this.loading = true;

                this.loadBipPackage();
            }
        });

        // open/close bip-card
        this.$watch('showForm', (value) => {
            if (value) {
                this.backList = false;
                this.showTest = false;
            }
        });
        // открыть/закрыть Критерии моделирования
        this.$watch('showTest', (value) => {
            if (value) {
                this.backList = false;
                this.showForm = false;
            }
        });
        // возврат к списку БИПов/ кнопка Назад к списку
        this.$watch('backList', (value) => {
            if (value) {
                this.showForm = false;
                this.showTest = false;
            }
        });

        this.$watch('selAbp', () => {
            this.selPrg = null;
        });
        this.$watch('selPrg', () => {
            this.selPpr = null;
        });
        this.$watch('check', (value) => {
            for (const row of this.showBipList) {
                if (checkAccess(row, 'abp_agree', 'abp_agree_recipient', this.selRegionBudget.code)) {
                    this.$set(row, 'check', value);
                }
            }
        });

        this.loadLocation();
        this.loadBudgetLevel();
        this.loadOperations();
        nameLocale(this.expTypes);

        loadVariants().then((data) => {
            this.versionList = data.map((v) => {
                const ver: any = Object.assign({}, v);
                itemNameLocale(ver);
                const str = (ver.status
                    ? getFilterTitle(this, 'apprvd') + (ver.attribute ?  ', ' + getFilterTitle(this, 'act') : '')
                    : (ver.attribute ? getFilterTitle(this, 'act') : ''));
                Object.defineProperty(ver, 'text', {
                    get: function () {
                        return ver['name'] + (str.length > 0 ? ' (' + str + ')' : '');
                    }
                });
                return ver;
            });
            versionByRegion(this);
        });
    }

    public mounted() {
        console.log(new Date().toISOString(), 'form mounted');
        this.host = (window.location.host === 'nsi.csi.kz' || window.location.host === 'localhost:10000');
        // console.log('host', window.location.host, (window.location.host === 'ea.csi.kz'));
        // const urlArray = location.hash.split('?')
        // if (urlArray.length>0) {
        //     const queryString = urlArray[1];
        //     const urlParams = new URLSearchParams(queryString);
        //     const id = urlParams.get('bip_id');
        //     if (id!=null) {
        //         for (const bip of this.bipList) {
        //             if (bip.id == parseInt(id)) {
        //                 this.openBip(bip);
        //                 break;
        //             }
        //         }
        //     }
        // }

        this.loadUserAbps();
        this.loadUserGus();
        this.loadBipPackageDicts();
    }

    // get global user region
    get localCode() {
        return store.state._instanceCode;
    }

    get userUiid() {
        return store.state.user.sub;
    }

    private get info_1() {
        let sms = 'Данный раздел предназначен для ввода, просмотра и редактирования данных бюджетной заявки '
            + 'на определенный плановый период в части бюджетных инвестиционных проектов.';
        if (`${i18nService.locale}` === 'kk') {
            sms = 'Бұл бөлім Бюджеттік инвестициялық жобалар бөлігінде белгілі бір жоспарлы кезеңге арналған '
                + 'бюджеттік өтінім деректерін енгізуге, қарауға және түзетуге арналған.'
        }
        if (`${i18nService.locale}` === 'en') {
            sms = 'This section is intended for entering, viewing and editing budget request data '
                + 'for a certain planning period in terms of budget investment projects.'
        }
        return sms;
    }

    private get info_2() {
        let sms = 'Расшифровка включает в себя: общие сведения по проекту, источники финансирования, '
            + 'финансирование до начала планового периода, показатели для оценки приоритетности '
            + 'проекта.';
        if (`${i18nService.locale}` === 'kk') {
            sms = 'Расшифровка мыналарды қамтиды: жоба бойынша жалпы мәліметтер, қаржыландыру көздері, '
                + 'жоспарлы кезең басталғанға дейін қаржыландыру, жобаның басымдылығын бағалауға арналған көрсеткіштер.';
        }
        if (`${i18nService.locale}` === 'en') {
            sms = 'The transcript includes: general information on the project, sources of financing, '
                + 'financing before the start of the planning period, indicators for assessing priority project.';
        }
        return sms;
    }

    private get editAccess(): boolean {
        return ([2, 3].includes(this.userLevel) && this.selVersion && this.selVersion.attribute);
    }

    private get yearsFilter(): any[] {
        return years();
    }

    private get versionFilter(): any[] {
        return filterVersion(this);
    }

    private get abpFilter(): any[] {
        const abps = new Set();

        for (const row of this.showBipList) {
            abps.add(parseInt(row.addDatas.abp.abp));
        }

        return Array.from(this.ebkMap.values()).filter((row) => abps.has(parseInt(row.abp)));
    }

    private get prgFilter(): any[] {
        if (this.selAbp) {
            const prgs = new Set();
            for (const row of this.showBipList) {
                for (const fin of row.addDatas['finace']) {
                    prgs.add(parseInt(fin['prg']));
                }
            }
            return this.selAbp.value.child.filter((row: any) => prgs.has(parseInt(row.prg)));
        }
        return [];
    }

    private get pprFilter(): any[] {
        if (this.selPrg) {
            const pprs = new Set();
            for (const bip of this.showBipList) {
                for (const fin of [...bip.newFinace, ...bip.newFinaceRegional]) {
                    pprs.add(parseInt(String(fin.ppr!== null ? fin.ppr : 0)));
                }
            }
            return this.selPrg.value.child.filter((row: any) => pprs.has(parseInt(row.ppr)));
        }
        return [];
    }

    private get bipTableFields() {
        return [
            {
                key: 'check',
                label: '',
                class: 'toggle-show'
            },
            {
                label: '№',
                key: 'nom'
            },
            {
                label: getTFieldTitle(this, 'project_code'),
                key: 'code',
                sortable: true
            },
            {
                label: getTFieldTitle(this, 'project_name'),
                key: 'name',
                sortable: true
            },
            {
                label: getCardTitle(this, 'sector_1', 'implementation_period'),
                key: 'period',
                sortable: true
            },
            {
                label: getFilterTitle(this, 'object_type'),
                key: 'object',
                sortable: true
            },
            {
                label: getTFieldTitle(this, 'location'),
                key: 'locality',
                sortable: true
            },
            {
                label: getTFieldTitle(this, 'source_funding_by_breakdown'),
                key: 'source'
            },
            {
                label: getCardTitle(this, 'sector_1', 'total_cost') + '(' + getCardTitle(this, 'sector_1', 'thousand_tg') + ')',
                key: 'totalCost',
                sortable: true
            },
            {
                label: getCardTitle(this, 'sector_1', 'cost_PIR_GE'),
                key: 'costWithout',
                sortable: true
            },
            {
                label: getCardTitle(this, 'sector_3', 'financing_before_plan_period') + '(' + getCardTitle(this, 'sector_1', 'thousand_tg') + ')',
                key: 'beforePlan'
            },
            {
                label: getTFieldTitle(this, 'amount_plan_period'),
                key: 'years'
            },
            {
                label: getTFieldTitle(this, 'remaining_funding') + '(' + getCardTitle(this, 'sector_1', 'thousand_tg') + ')',
                key: 'balance',
                sortable: true
            },
            {
                label: getTFieldTitle(this, 'project_status'),
                key: 'status',
                sortable: true
            },
            {
                key: 'comments',
                label: getTFieldTitle(this, 'comments')
            },
            {
                key: 'more',
                label: ''
            }
        ];
    }

    private get selectedLang() {
        return i18nService.locale
    }

    private get showBipList(): BipProjectDataClass[] {
        let result = this.bipList.filter(bip => !bip.isDeleted);

        if (this.selVersion) {
            result = result.filter(bip => bip.variant === this.selVersion.variant_uuid
                || bip.variant_recipient === this.selVersion.variant_uuid);
        }

        if (this.selAbp) {
            result = result.filter(row => (parseInt(row.addDatas.abp.abp) === parseInt(this.selAbp.abp)));
        }

        const prgs: any[] = [];
        if (this.selPrg) {
            for (const bip of result) {
                for (const fin of bip?.addDatas['finace']) {
                    if (parseInt(this.selPrg.prg) === parseInt(fin.prg)) {
                        prgs.push(bip.id);
                    }
                }
            }
            result = result.filter(row => prgs.includes(row.id));
        }

        const pprs: any[] = [];
        if (this.selPpr) {
            for (const bip of result) {
                for (const fin of bip?.addDatas['finace']) {
                    if (parseInt(this.selPpr.ppr) === parseInt(fin.ppr)) {
                        pprs.push(bip.id);
                    }
                }
            }
            result = result.filter(row => pprs.includes(row.id));
        }

        result = result.filter(bip => (this.userAbps.includes(bip?.header.abp)
            || (bip?.header.regional && this.userAbps.includes(bip?.header.regional.abp))));
        return result;
    }

    // methods by abc
    private editBip(bip: BipProjectDataClass | null): boolean {
        const code = '0';
        if (bip !== null) {
            if (bip.id === 0) {
                return true;
            } else {
                let result = false;
                if (bip.addDatas.status && bip.addDatas.status.operation_code !== null && bip.addDatas.status.steps) {
                    const operation_codes: any[] = [];
                    for (const step of bip.addDatas.status.steps) {
                        if (step.operation_code !== null) {
                            step.operation_code.forEach((oper: string) => {
                                operation_codes.push(oper);
                            });
                        }
                    }
                    if (bip.header?.finalAbp === 1) {
                        result = bip.addDatas.status.operation_code.includes('gu_edit')
                            && operation_codes.includes('abp_agree')
                            && checkGu(this.userGus, bip);
                    }
                    if (bip.header?.finalAbp === 0 && bip.header.regional) {
                        result = bip.addDatas.status.operation_code.includes('gu_edit')
                            && operation_codes.includes('abp_agree_recipient')
                            && checkGuReg(this.userGus, bip)
                            && this.selRegionBudget.code === bip.district_budget;
                    }
                    if (bip.code === code) {
                        console.log('editBip1', this.editAccess, operation_codes, bip);
                    }
                }
                if (bip.code === code) {
                    console.log('editBip2', result, this.editAccess, bip);
                }
                return (result && this.editAccess) || !bip.addDatas.status;
            }
        }
        return false;
    }

    private editSection4(bip: BipProjectDataClass | null): boolean {
        const code = '0';
        if (bip !== null) {
            if (bip.addDatas.status
                && bip.addDatas.status.operation_code !== null
                && bip.addDatas.status.steps) {
                const operation_codes: any[] = [];
                for (const step of bip.addDatas.status.steps) {
                    if (step.operation_code !== null) {
                        step.operation_code.forEach((oper: string) => {
                            operation_codes.push(oper);
                        });
                    }
                }
                if (bip.header?.finalAbp === 0
                    && bip.header.regional
                    && !operation_codes.includes('abp_agree_recipient')
                    && operation_codes.includes('abp_agree')) {

                    if (bip.code === code) {
                        console.log('editSection4 1', bip);
                    }
                    return (checkGu(this.userGus, bip)
                        && this.selRegionBudget.code === bip.region_budget
                        && bip.addDatas.status.operation_code.includes('gu_edit'));
                } else {
                    if (bip.code === code) {
                        console.log('editSection4 2', bip);
                    }
                    return this.editBip(bip) || !bip.addDatas.status;
                }
            }
            if (bip.code === code) {
                console.log('editSection4 3', bip);
            }
            return this.editBip(bip) || !bip.addDatas.status;
        }
        return false;
    }

    async addNewBip() {
        this.$root.$emit('removeClass');
        this.bipProjectData = new BipProjectDataClass();
        this.bipProjectData.header.year = this.selVersion.year;
        this.bipProjectData.header.dataType = this.selVersion.data_type;
        this.bipProjectData.header.finalAbp = 1;
        defineBip(this, this.bipProjectData, 'form');
        this.showForm = true;
    }

    private addNewStatus(old: any, data: any,
                         bip_code: string,
                         abp: number, gu: string, year: number,
                         data_type: number, region: string,
                         variant: string, comment_txt: string) {
        data.bip_code = bip_code;
        data.abp = abp;
        data.gu = gu;
        data.cur_year = year;
        data.region = region;
        data.data_type = data_type;
        data.variant = variant;
        data.comment_txt = comment_txt;
        data.user_id = this.userUiid;
        return Object.assign({old: old}, data);
    }

    // кнопка действия
    async agreementEvent() {
        let firstStatus = null;
        let firstHeader = null;
        this.agreeList = [];
        this.agrBtnLst = {back: [], forward: []};
        for (const row of this.showBipList) {
            if (row.check && row.addDatas.status) {
                if (firstStatus === null) {
                    firstStatus = row.addDatas.status.code;
                    firstHeader = row.header.hasOwnProperty('regional');
                }
                if (firstStatus !== row.addDatas.status.code) {
                    makeToast(this, 'danger', getSmsTitle(this, 'coordination'),
                        getSmsTitle(this, 'sel_BIPs_with_identical_stat'));
                    return;
                }
                if (firstHeader !== row.header.hasOwnProperty('regional')) {
                    makeToast(this, 'danger', getSmsTitle(this, 'coordination'),
                        getSmsTitle(this, 'sel_BIPs_only_with_or_without_recipient'));
                    return;
                }
                this.agreeList.push(row.addDatas.status);
            }
        }
        if (this.agreeList.length === 0) {
            makeToast(this, 'warning', getSmsTitle(this, 'coordination'),
                getSmsTitle(this, 'no_project_selected'));
            return;
        }
        if (firstHeader) {
            this.operationCode.push('abp_agree_recipient');
        }
        this.operationCode = this.operationCode.filter((item) => item !== 'gu_edit');

        const params = {modeCode: this.mode_code, operationCode: this.operationCode, agrCode: firstStatus};
        let nextSteps = null;
        try {
            const response = await fetch(`/api-py/get-step-next-back/${encodeURI(JSON.stringify(params))}`);
            nextSteps = await response.json();
            nameLocale(nextSteps);
        } catch (error) {
            makeToast(this, 'danger', getSmsTitle(this, 'error_request'),
                getSmsTitle(this, 'error_request') + ' /get-agreement-step-next-back');
            return;
        }
        if (nextSteps.length === 0) {
            makeToast(this, 'warning', getSmsTitle(this, 'coordination'),
                getSmsTitle(this, 'no_approval_steps_available'));
            return;
        }
        const back: any[] = [];
        const forward: any[] = [];
        if (firstStatus === 1) {
            for (const el of nextSteps) {
                if (el.stepType === 1) {
                    if (firstHeader) {
                        if (el.operationCode === 'abp_agree_recipient') {
                            back.push(el);
                        }
                    } else {
                        if (el.operationCode === 'abp_agree') {
                            back.push(el);
                        }
                    }
                } else {
                    if (firstHeader) {
                        if (el.operationCode === 'abp_agree_recipient') {
                            forward.push(el);
                        }
                    } else {
                        if (el.operationCode === 'abp_agree') {
                            forward.push(el);
                        }
                    }
                }
            }
        } else {
            for (const el of nextSteps) {
                if (el.stepType === 1) {
                    back.push(el);
                } else {
                    forward.push(el);
                }
            }
        }
        this.$set(this.agrBtnLst, 'back', back);
        this.$set(this.agrBtnLst, 'forward', forward);

        this.$refs.refAgrModal.showEvent();
    }

    async agrClick(agree: any) {
        const newAgree = [];
        const checkList = this.showBipList.filter(row => row.check && row.addDatas.status);
        for (const bip of checkList) {
            if (bip.header?.finalAbp === 1) {
                newAgree.push(this.addNewStatus(bip.addDatas.status, agree,
                    bip.code, bip.header.abp, bip.header.gu.code,
                    this.selVersion.year, this.selVersion.data_type,
                    this.selVersion.region_code, this.selVersion.variant_uuid,
                    agree.commentTxt));
            } else {
                if (checkGu(this.userGus, bip) && this.selRegionBudget.code === bip.region_budget) {
                    // console.log('сижу под Инициатором')
                    await forInitiator(this, 'uebp', bip, this.versionList)
                        .then((data: any) => {
                            if (data.versions_rec && data.versions_rec.length > 0 && data.findDB === 0) {
                                // Статус Инициатора
                                newAgree.push(this.addNewStatus(bip.addDatas.status, agree,
                                    bip.code, bip.header.abp, bip.header.gu.code,
                                    this.selVersion.year, this.selVersion.data_type,
                                    this.selVersion.region_code, this.selVersion.variant_uuid,
                                    agree.commentTxt));
                                // Статус Получателя
                                newAgree.push(this.addNewStatus(bip.addDatas.status, agree,
                                    bip.code, bip.header.regional.abp, bip.header.regional.gu.code,
                                    data.versions_rec[0].year, data.versions_rec[0].data_type,
                                    data.versions_rec[0].region_code, data.versions_rec[0].variant_uuid,
                                    agree.commentTxt));
                                this.saveForm(bip, this.selVersion.variant_uuid, data.versions_rec[0].variant_uuid, false);
                            }
                        });
                }
                if (checkGuReg(this.userGus, bip) && this.selRegionBudget.code === bip.district_budget) {
                    // console.log('сижу под Получателем')
                    await forRecipient(this, 'uebp', bip,  this.versionList)
                        .then((data: any) => {
                            if (data.versions && data.versions.length > 0 && data.findDB === 0) {
                                // Статус Инициатора
                                newAgree.push(this.addNewStatus(bip.addDatas.status, agree,
                                    bip.code, bip.header.abp, bip.header.gu.code,
                                    data.versions[0].year, data.versions[0].data_type,
                                    data.versions[0].region_code, data.versions[0].variant_uuid,
                                    agree.commentTxt));
                                // Статус Получателя
                                newAgree.push(this.addNewStatus(bip.addDatas.status, agree,
                                    bip.code, bip.header.regional.abp, bip.header.regional.gu.code,
                                    this.selVersion.year, this.selVersion.data_type,
                                    this.selVersion.region_code, this.selVersion.variant_uuid,
                                    agree.commentTxt));
                                this.saveForm(bip, data.versions[0].variant_uuid, this.selVersion.variant_uuid, false);
                            }
                        });
                }
            }
        }
        if (newAgree.length > 0) {
            postData('/api-py/set_bip_agreement_step', newAgree).then((response) => {
                const list = response;
                this.loadBipAgreement();
                for (const res of list) {
                    if (res.result === 'error') {
                        makeToast(this, 'danger', getSmsTitle(this, 'error_save'), `${res.errTxt}`);
                    } else {
                        makeToast(this, 'success', getSmsTitle(this, 'saving'),
                            getSmsTitle(this, 'success'));
                    }
                }
            });
            setTimeout(() => {
                this.updateAgreement();
            }, 3000);
        }
        for (const row of this.showBipList) {
            this.$set(row, 'check', false);
        }
    }

    private checkAccess(bip: BipProjectDataClass) {
        return checkAccess(bip, 'abp_agree', 'abp_agree_recipient', this.selRegionBudget.code);
    }

    private commentClk(agrObj: any) {
        if (agrObj !== null) {
            this.$set(agrObj, 'mode_code', 'bip');
            this.$refs.refHistModal.showEvent(agrObj);
        }
    }

    private deleteAccess(bip: BipProjectDataClass) {
        let result = false;
        if (bip && bip.addDatas.status && bip.addDatas.status.operation_code !== null) {
            result = bip.addDatas.status.operation_code.includes('gu_edit');
        }
        if (bip && bip.header.hasOwnProperty('regional') && bip.header.regional) {
            result = result && (bip.district_budget === this.selRegionBudget.code);
        } else {
            result = result && (bip.region_budget === this.selRegionBudget.code);
        }
        return result;
    }

    private async deleteBip(item: BipProjectDataClass) {
        const versions = this.versionList.filter(row => row.variant_uuid === item.variant);
        if (versions.length > 0 && !versions[0].attribute) {
            makeToast(this, 'danger', getSmsTitle(this, 'removal'),
                getSmsTitle(this, 'del_project_impossible_initiator_version_not_current'));
            return;
        }

        if (item.header.regional && !this.userGus.includes(item.header.regional.gu.code)) {
            makeToast(this, 'danger', getSmsTitle(this, 'removal'),
                getSmsTitle(this, 'del_project_possible_only_by_recipient'));
            return;
        }

        await checkFinCosts(this, item).then(() => {
            let checkCost = false;
            item.newFinace.forEach((row: any) => {
                checkCost = checkCost || row.edited;
            })
            item.newFinaceRegional.forEach((row: any) => {
                checkCost = checkCost || row.edited;
            })

            if (checkCost) {
                makeToast(this, "danger", getSmsTitle(this, 'removal'),
                    getSmsTitle(this, 'project_cannot_deleted_remove_supported_amount'));
                return;
            } else {
                this.$bvModal.msgBoxConfirm(
                    getSmsTitle(this, 'del_project_cannot_restored') + item.code + '?',
                    {
                        title: getSmsTitle(this, 'confirmation'),
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        cancelVariant: 'light',
                        okTitle: getBipTitle(this, 'btn.ok'),
                        cancelTitle: getBipTitle(this, 'btn.cancel'),
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        modalClass: 'del-item-modal-script'
                    }).then(value => {
                    if (value) {
                        this.saveBip(item, false, true, true);
                    }
                }).catch(error => {
                    makeToast(this, 'danger', getSmsTitle(this, 'error_delete'), error.toString());
                    this.saving = false;
                });
            }
        });
    }

    async downloadRep68() {
        if (this.selAbp === null || this.selPrg === null) {
            makeToast(this, 'primary', getSmsTitle(this, 'warning'),
                getSmsTitle(this, 'sel_ABP_BP'));
            return;
        } else {
            let gu: IGu | null = null;
            let len = 0
            for (const bip of this.showBipList) {
                if (bip.variant === this.selVersion.variant_uuid) {
                    if (this.selAbp.abp === bip.header.abp) {
                        gu = bip.header.gu;
                    }

                    len += bip.newFinace.length;
                }

                if (bip.variant_recipient === this.selVersion.variant_uuid) {
                    if (this.selAbp.abp === bip.header.regional.abp) {
                        gu = bip.header.regional.gu;
                    }

                    len += bip.newFinaceRegional.length;
                }
            }

            /** Объект для передачи в отчет */
            const params: { [key: string]: any } = {
                lang: this.selectedLang,
                year: this.planPeriod.year,
                data_type: this.selDataType.name,
                variant_uuid: this.selVersion.variant_uuid,
                version_name: this.selVersion.name,
                abp: this.selAbp.abp,
                abp_name: this.selAbp.value.name,
                prg: this.selPrg.prg,
                prg_name: this.selPrg.value.name
            };

            if (len === 0) {
                makeToast(this, 'danger', getBipTitle(this, 'app') + ' 68',
                    getSmsTitle(this, 'information_financing_plan_period_not_filled'));
                return;
            }

            const signParams = {
                code_modules: "004.002.007",
                abp: this.selAbp.abp,
                code_prg: this.selPrg.prg,
                code_gu: gu?.code,
                code_forms: "",
                budget_variants: this.selVersion.variant_uuid,
            };
            const signers = await this.getSigners(signParams);
            if (signers['sign_code'].length > 0)
                signers['sign_code'].sort(sortByField("order_num"));

            if (signers['sign_code'].length === 1
                && signers['sign_code'][0].id_user.length == 0) {

                makeToast(this, 'warning', getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'absent_signatories'));
            }
            Ax(
                {
                    url: "/api-py/bip-application-68",
                    method: "GET",
                    params,
                    responseType: "blob"
                },
                (data) => {

                    if (data) {
                        const url = window.URL.createObjectURL(new Blob([data as BlobPart]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", getBipTitle(this, 'app') + '_68_'
                            + this.selAbp.abp + "_"
                            + this.selPrg.prg + "_"
                            + this.selDataType.name + "_"
                            + this.planPeriod.year + ".xls");
                        document.body.appendChild(link);
                        link.click();
                    }
                },
                (error) => {
                    makeToast(this, "danger", getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'error_request') + ' /bip-application-68');
                }
            );
        }
    } // Приложение 68.xls

    private findItem(code: number, field: string, list: any[]): any {
        return findItem(code, field, list);
    }

    getBeforePlan(bip: BipProjectDataClass) {
        let result = 0;
        for (const obj of bip.oldFinace) {
            // @ts-ignore
            result += getNumber(obj['totalCoast']);
        }
        return result;
    }

    private getPrePeriodFunding(arr: RowNewFinanceData[]): number {
        let result = 0;
        for (const obj of arr) {
            for (const key of Object.keys(obj)) {
                // @ts-ignore
                if (!isNaN(parseInt(obj[key])) && key < this.planPeriod.year) {
                    // @ts-ignore
                    result += parseInt(obj[key]);
                }
            }
        }
        return result;
    }

    private getPeriodSum(bip: BipProjectDataClass) {
        let res = 0;
        const listYears = [parseInt(bip.header.year), parseInt(bip.header.year) + 1, parseInt(bip.header.year) + 2];
        let finace = [];
        if ((bip.header.finalAbp === 1) || (this.selVersion.variant_uuid === bip.variant)) {
            finace = bip.newFinace;
        } else {
            finace = bip.newFinaceRegional;
        }
        if (finace) {
            for (const d of finace) {
                for (let i = bip.period.begYear; i <= bip.period.endYear; i++) {
                    // @ts-ignore
                    if (listYears.includes(i) && d[i] != undefined && d[i] !== '') {
                        // @ts-ignore
                        res = res + parseFloat(d[i]);
                    }
                }
            }
        }
        return res;
    }

    async getSigners(params: any) {
        try {
            const response = await fetch('/api-py/get_signatories_data_by_budget_variants/',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    body: JSON.stringify(params)
                });
            return await response.json();
        } catch {
            makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                getSmsTitle(this, 'error_request') + ' /get_signatories_data_by_budget_variants');
        }
    }

    private getSources(bip: BipProjectDataClass) {
        let res = '';
        const arr = [];
        let finace = [];
        if ((bip.header.finalAbp === 1) || (this.selVersion.variant_uuid === bip.variant)) {
            finace = bip.newFinace;
        } else {
            finace = bip.newFinaceRegional;
        }
        for (const d of finace) {
            arr.push(d.ppr);
        }
        arr.sort((a: any, b: any) => (a - b > 0) ? 1 : -1);
        for (const a of arr) {
            if (a !== null) {
                switch (a) {
                    case 5:
                        res = (res.length > 0 ? res += ', ВЗ' : 'ВЗ');
                        break;
                    case 11:
                        res = (res.length > 0 ? res += ', РБ' : 'РБ');
                        break;
                    case 15:
                        res = (res.length > 0 ? res += ', МБ' : 'МБ');
                        break;
                    case 28:
                        res = (res.length > 0 ? res += ', ОБ' : 'ОБ');
                        break;
                    case 32:
                        res = (res.length > 0 ? res += ', НФ' : 'НФ');
                        break;
                    case 42:
                        res = (res.length > 0 ? res += ', 042' : '042');
                        break;
                    default:
                        break;
                }
            }
        }
        return res;
    }

    private getYearsSum(bip: BipProjectDataClass) {
        let res = 0;
        for (const old of bip.oldFinace) {
            res += old.totalCoast;
        }
        let finace = [];
        if (bip.header.finalAbp === 1) {
            finace = bip.newFinace;
        } else {
            finace = bip.newFinaceRegional;
        }
        if (finace) {
            for (const fin of finace) {
                for (const [key, value] of Object.entries(fin)) {
                    if (key.toString().length === 4 && parseInt(key) >= bip.header.year) {
                        if (value) {
                            res += getNumber(value);
                        }
                    }
                }
            }
        }
        return res;
    }

    private infoBip(info_id: number, file: string) {
        Ax(
            {
                url: '/api-py/get-info/' + info_id,
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data as BlobPart]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file + '.pdf');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                makeToast(this, 'danger', getSmsTitle(this, 'error_load'), error.toString());
            }
        );
    }

    // ------------------ user's budget_level ------------------
    private async loadBudgetLevel() {
        const budgetLevel: number[] = [];
        try {
            const result = await fetch(encodeURI('/api-py/get-user-budget-level/' + this.userUiid))
                .then(response => response.json());
            for (const el of result) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const indx = el.budget_level.lastIndexOf('_');
                if (indx >= 0) {
                    budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
                }
            }
            this.levels = [];
            this.regLevels = [];
            if (budgetLevel.includes(2)) {
                this.levels.push(2);
                this.regLevels.push(3);
            }
            if (budgetLevel.includes(3)) {
                this.levels.push(2);
                this.levels.push(3)
                this.regLevels.push(3);
                this.regLevels.push(4);
            }
            if (budgetLevel.includes(4)) {
                this.levels.push(4);
                this.levels.push(3);
                this.regLevels.push(4);
            }
        } catch (error) {
            makeToast(this, 'danger', getSmsTitle(this, 'error_request') + ' /get-user-budget-level',
                error.toString());
            return;
        } finally {
            console.log(new Date().toISOString(), 'form budgetLevel = ', budgetLevel.length);
        }
        if (budgetLevel.length === 0) {
            makeToast(this, 'warning', getSmsTitle(this, 'attention'),
                getSmsTitle(this, 'no_available_user_budget_levels'));
        }
    }

    private async loadBipAgreement(code: string = '') {
        const params = JSON.stringify({
            variant: this.selVersion.variant_uuid,
            mode_code: this.mode_code,
            operation_code: this.operationCode
        });
        try {
            this.agrMap = new Map();
            const response = await fetch(`/api-py/get-bip-agreement-by-params/${encodeURI(params)}`)
                .then((response) => response.json());
            this.agrMap = new Map(Object.entries(response));

            const bip = this.bipList.filter(row => row.code === code);
            if (bip.length > 0) {
                const item = bip[0];
                const agr = this.agrMap.get(code);
                if (agr) {
                    itemNameLocale(agr);
                };
                this.$set(item['addDatas'], 'status', agr);
            }
        } catch (error) {
            makeToast(this, 'danger', getSmsTitle(this, 'attention'),
                getSmsTitle(this, 'error_request') + ' /get-bip-agreement-by-params');
        } finally {
            console.log(new Date().toISOString(), 'form agrMap = ', this.agrMap.size);
        }
    }

    private defineBipList(serverList: any[]) {
        try {
            for (const bip of serverList) {
                const bipObject = BipProjectDataClass.fromJSON(bip);
                defineBip(this, bipObject, 'form');
                this.bipList.push(bipObject);
                this.bipProjectData = bipObject;

                this.pBar = (bipObject.id % 2 === 0 ? this.pBar + 20 : this.pBar - 20);
                this.loading = true;
            }
        } catch (error) {
            console.log('defineBipList error => ' + error);
        } finally {
            console.log(new Date().toISOString(), 'formList', this.bipList.length);
            this.loading = false;
            this.pBar = 100;
        }
    }

    private async loadBudgetRegions() {
        try {
            const response = await fetch('/api-py/get-user-regions-by-obl/' + this.location.obl + '/' + this.userUiid)
                .then((response) => response.json());
            this.regionBudgetList = response;
        } catch (error) {
            makeToast(this, 'danger', getSmsTitle(this, 'error_request') + ' /get-user-regions-by-obl',
                error.toString());
        } finally {
            console.log(new Date().toISOString(), 'form regionBudgetList = ', this.regionBudgetList.length);
            nameLocale(this.regionBudgetList);
            this.selRegionBudget = this.regionBudgetList[0];
        }
    } // справочник регионов

    // запрос локации по глобальному коду области
    private async loadLocation() {
        try {
            const response = await fetch('/api-py/get-budget-obl/' + this.localCode)
                .then(response => response.json());
            this.location = response;
        } catch (error) {
            makeToast(this, 'danger', getSmsTitle(this, 'error_request') + ' /get-budget-obl/',
                error.toString());
        } finally {
            console.log(new Date().toISOString(), 'form location = ', this.location);
            await this.loadBudgetRegions();
            await this.loadRegions();
        }
    }

    private async loadOperations() {
        let result = null;
        try {
            result = await fetch(encodeURI(`/api/um/module/link?user=${store.state.user.sub}&modulecode=004.002.007`))
                .then((response) => response.json());
        } catch (error) {
            makeToast(this, 'danger', getSmsTitle(this, 'error_request') + ' /um/module/link?user',
                error.toString());
            return;
        } finally {
            if (result.operations) {
                this.operationCode = result.operations;
            }
            if (result.accessLevel) {
                this.userLevel = result.accessLevel;
            }
        }
    }

    private async loadRegions() {
        try {
            const response = await fetch('/api-py/get-kato-regions/' + this.location.code)
                .then((response) => response.json());
            for (const region of response) {
                if (region.code.substring(2, 3) !== '0') {
                    try {
                        const locals = await fetch('/api-py/get-kato-localities/' + region.code.substring(0, 4))
                            .then((response) => response.json());
                        nameLocale(locals);
                        locals.unshift({name: ''});
                        this.$set(region, 'localityList', locals);
                        this.regionList.push(region);
                    } catch (error) {
                        makeToast(this, 'danger', getSmsTitle(this, 'attention'),
                            getSmsTitle(this, 'error_request') + ' /get-kato-localities');
                    }
                }
            }
        } catch (error) {
            makeToast(this, 'danger', getSmsTitle(this, 'error_request') + ' /get-kato-regions',
                error.toString());
        } finally {
            console.log(new Date().toISOString(), 'form regionList = ', this.regionList.length);
            nameLocale(this.regionList);
        }
    }

    private async loadUserAbps() {
        if (this.userUiid && this.userAbps.length === 0) {
            try {
                const response = await fetch('/api-py/user-abp/' + this.userUiid)
                    .then((response) => response.json());
                for (const abp of response) {
                    this.userAbps.push(abp.abp);
                }
            } catch (error) {
                makeToast(this, 'danger', getSmsTitle(this, 'error_request') + ' /user-abp',
                    error.toString());
            } finally {
                console.log(new Date().toISOString(), 'form userAbps = ', this.userAbps.length);
            }
        }
    }

    private async loadUserGus() {
        if (this.userUiid && this.userGus.length === 0) {
            try {
                const response = await fetch('/api-py/user-gu/' + this.userUiid)
                    .then((response) => response.json());
                for (const item of response) {
                    this.userGus.push(item.gu);
                }
            } catch (error) {
                makeToast(this, 'danger', getSmsTitle(this, 'error_request') + ' /user-gu',
                    error.toString());
            } finally {
                console.log(new Date().toISOString(), 'form userGus = ', this.userGus.length);
            }
        }
    }

    async loadEbkMap() {
        try {
            // fill abpBase
            this.abpBase = [];
            if (this.levels.length > 0 && this.ebkMap) {
                for (const ebk of this.ebkMap.values()) {
                    itemNameLocale(ebk, 'abp');
                    itemNameLocale(ebk.value, 'abp');
                    for (const prg of ebk.value.child) {
                        itemNameLocale(prg, 'prg');
                        itemNameLocale(prg.value, 'prg');
                        for (const ppr of prg.value.child) {
                            itemNameLocale(ppr, 'ppr');
                            itemNameLocale(ppr.value, 'ppr');
                        }
                    }

                    if (this.levels.includes(ebk.value.budget_level_id)) {
                        this.abpBase.push(ebk.value);
                    }
                }
                this.abpBase.sort(sortByField('abp'));
            } else {
                makeToast(this, 'danger', getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'no_access_level_by_initiator'));
            }

            // fill regAbpBase
            this.regAbpBase = [];
            if (this.regLevels.length > 0 && this.ebkMap) {
                for (const ebk of this.ebkMap.values()) {
                    if (this.regLevels.includes(ebk.value.budget_level_id)) {
                        this.regAbpBase.push(ebk.value);
                    }
                }
                this.regAbpBase.sort(sortByField('abp'));
            } else {
                makeToast(this, 'danger', getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'no_access_level_by_recipient'));
            }

        } catch (error) {
            makeToast(this, "danger", getSmsTitle(this, 'error_request') + " /cost-tree",
                error.toString());
        } finally {
            console.log(new Date().toISOString(), 'form ebkTree', this.ebkMap.size);
            console.log(new Date().toISOString(), 'form abpBase = ', this.abpBase.length);
            console.log(new Date().toISOString(), 'form regAbpBase = ', this.regAbpBase.length);
        }
    }

    async loadBipPackageDicts() {
        let bipCriteriaValues = [];
        const params = ['budget_data_types', 'bip_project_type_list', 'bip_project_object_list',
            'agreement_status', 'program',
            'unit', 'bip_cif_list', 'bip_link_types',
            'bip_link_criterias', 'bip_criteria_values'];
        try {
            const response = await fetch(encodeURI("/api-py/bip-package-dicts/" + JSON.stringify(params)))
                .then((response) => response.json());

            this.dataTypeList = response['budget_data_types'];
            this.directionList = response['bip_project_type_list'].filter(
                (row: any) => !['7', '8'].includes(row.code));
            this.objectList = response['bip_project_object_list'];
            this.statusList = response['agreement_status'].sort(sortByField('name_ru'));
            this.gpList = response['program'];

            this.dictUnit = response['unit'].sort(sortByField('code'));
            this.bipCifList = response['bip_cif_list'].sort(sortByField('code'));
            this.bipLinkTypes = response['bip_link_types'].sort(sortByField('id'));
            this.bipLinkCriterias = response['bip_link_criterias'];
            bipCriteriaValues = response['bip_criteria_values'];
        } catch (error) {
            makeToast(this, 'danger', getSmsTitle(this, 'error_request') + ' /bip-package-dicts',
                error.toString());
        } finally {
            console.log(new Date().toISOString(), 'form BipPackageDicts');

            nameLocale(this.dataTypeList);
            nameLocale(this.directionList);
            for (const item of this.directionList) {
                nameLocale(item.object_types);
            }
            nameLocale(this.objectList);
            nameLocale(this.statusList);
            nameLocale(this.gpList);
            nameLocale(this.dictUnit);
            nameLocale(this.bipCifList);
            nameLocale(this.bipLinkTypes);
            nameLocale(this.bipLinkCriterias);
            nameLocale(bipCriteriaValues);

            const criteriaValues: Map<any, any> = new Map();
            for (const row of bipCriteriaValues) {
                criteriaValues.set(getRowKey(row, ['criteria', 'link', 'year']), row);
            }
            this.criteriaValues = criteriaValues;

            for (const blc of this.bipLinkCriterias) {
                try {
                    const cif = this.bipCifList.filter((row: any) => row.code === blc.criteria);
                    if (cif.length > 0) {
                        for (const [key, value] of Object.entries(cif[0])) {
                            if (!['id', 'begin_date', 'end_date'].includes(key)) {
                                this.$set(blc, key, value);
                            }
                        }
                        this.$set(blc, 'unit_item', findItem(blc.unit, 'national_symbol', this.dictUnit));
                    }

                    const blt = this.bipLinkTypes.filter((row: any) => row.id === blc.link);
                    if (blt.length > 0) {
                        for (const [key, value] of Object.entries(blt[0])) {
                            if (key !== 'id') {
                                this.$set(blc, key, value);
                            }
                        }

                        this.$set(blc, 'begin_year', new Date(blt[0].begin_date).getFullYear());
                        if (blt[0].end_date !== null) {
                            this.$set(blc, 'end_year', new Date(blt[0].end_date).getFullYear());
                        } else {
                            this.$set(blc, 'end_year', null);
                        }
                    }
                } catch (error) {
                    console.log('criteria', blc.id, error.toString());
                }
            }
        }
    }

    async loadBipPackage() {
        const versions = this.versionList.filter(row => row.status
            && row.year === this.planPeriod.year - 1
            && row.date_ueb !== null
            && row.region_code === this.selRegionBudget.code)
            .sort(sortByField('date_ueb'));

        const params = JSON.stringify({
            variant: this.selVersion.variant_uuid,
            mode_code: this.mode_code,
            operation_code: this.operationCode,
            mode: 'form',
            prev_variant: (versions.length > 0 ? versions[versions.length - 1].variant_uuid : '')
        });

        let serverList: any[] = [];
        this.pBar = 10;

        try {
            const response = await fetch(`/api-py/bip-package/${encodeURI(params)}`)
                .then((response) => response.json());
            this.ebkMap = new Map(Object.entries(response.abpMap));
            this.agrMap = new Map(Object.entries(response.agrMap));

            this.spfList = response.spfList.sort(sortByField('spf')).filter((row: any) => row.type === 4);
            nameLocale(this.spfList, 'spf');

            this.prevList = response.prevList;
            serverList = await response.bipList;

        } catch (error) {
            makeToast(this, "danger", getSmsTitle(this, 'error_request') + ' /bip-package', error.toString());
        } finally {
            console.log(new Date().toISOString(), 'form serverList = ', serverList.length);
            await this.loadEbkMap();
            // Для отображения в multiselect код BIP и имени
            this.prevList.forEach((bip: any) => bip.nameCode = `${bip.code} - ${bip.nameRu}`)
            this.prevList.sort(sortByField('code'));

            if (serverList.length > 0 && this.ebkMap.size > 0) {
                this.pBar = 45;
                setTimeout(() => {
                    this.defineBipList(serverList);
                }, 3000);
            } else {
                makeToast(this, 'warning', getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'no_datas_by_version'))
                this.pBar = 100;
                this.loading = false;
            }
        }
    }

    private message(form: BipProjectDataClass, sms: string) {
        this.$bvModal.msgBoxConfirm(
            sms, {
                title: getSmsTitle(this, 'attention'),
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: getBipTitle(this, 'btn.cancel'),
                cancelTitle: getBipTitle(this, 'btn.correct'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                modalClass: 'del-item-modal-script'
            }).then(value => {
            this.saving = false;
            if (value) {
                this.showForm = false;
                this.backList = true;
                return;
            } else {
                if (!this.showForm) {
                    this.openBip(form, false);
                }
            }
        }).catch(error => {
            makeToast(this, 'danger', getSmsTitle(this, 'error_control'), error.toString());
        });
    }

    public modeling() {
        this.showTest = !this.showTest;
        this.$root.$emit('bipModel', true);
        this.$root.$emit('removeClass', true);
    }

    private openBip(bip: BipProjectDataClass, emitOn: Boolean = true) {
        this.bipProjectData = BipProjectDataClass.assign(bip);
        this.showForm = true;
        if (emitOn) {
            this.$root.$emit('removeClass');
        }
    }

    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        let refItem: any;
        switch (refName) {
            case 'bcPlanYear':
                refItem = this.$refs.bcPlanYear;
                break;
            case 'bcRegionBudget':
                refItem = this.$refs.bcRegionBudget;
                break;
            case 'bcDataType':
                refItem = this.$refs.bcDataType;
                break;
            case 'bcVersion':
                refItem = this.$refs.bcVersion;
                break;
            case 'bcAbp':
                refItem = this.$refs.bcAbp;
                break;
            case 'bcPrg':
                refItem = this.$refs.bcPrg;
                break;
            case 'bcPpr':
                refItem = this.$refs.bcPpr;
                break;
            default:
                break;
        }
        setTimeout(() => refItem.$el.focus(), 100);
    }

    private async reCalculate() {
        this.$bvModal.msgBoxConfirm(
            getSmsTitle(this, 'score_be_recalculated_saved'),
            {
                title: getSmsTitle(this, 'confirmation'),
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                cancelVariant: 'light',
                okTitle: getBipTitle(this, 'btn.ok'),
                cancelTitle: getBipTitle(this, 'btn.cancel'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                modalClass: 'del-item-modal-script'
            }).then(value => {
            if (value) {
                this.updateMark = true;
                this.saving = true;
            } else {
                return;
            }
        }).catch(error => {
            makeToast(this, 'danger', getSmsTitle(this, 'error_update'), error.toString());
        });
        this.updateMark = false;
    }

    private async saveBip(form: BipProjectDataClass, equal: boolean, save: boolean, delet: boolean) {
        if (form.header.finalAbp === 1) {
            // если получателя нет
            if (!checkGu(this.userGus, form)) {
                this.message(form, getSmsTitle(this, 'user_gu_match_initiator_gu'));
                return;
            }
            if (this.selRegionBudget.code !== form.region_budget) {
                this.message(form, getSmsTitle(this, 'gu_region_match_filter_region'));
                return;
            }

            const versions = this.versionList.filter(row => row.attribute
                && row.year === this.planPeriod.year
                && row.data_type === parseInt(this.selDataType.code)
                && row.region_code === form?.region_budget);
            if (versions.length === 0) {
                let sms = 'Данные не будут сохранены, так как отсутствует актуальная версия бюджета:'
                    + ' Период - ' + this.planPeriod.name + ', Вид данных - ' + this.selDataType.code
                    + ', Регион бюджета - ' + form?.region_budget;
                if (`${i18nService.locale}` === 'kk') {
                    sms = 'Деректер сақталмайды, өйткені бюджеттің өзекті нұсқасы жоқ:'
                        + ' Кезең - ' + this.planPeriod.name + ', Деректер түрі - ' + this.selDataType.code
                        + ', Бюджет аймағы - ' + form?.region_budget;
                }
                if (`${i18nService.locale}` === 'en') {
                    sms = 'The data will not be saved because the current budget version is missing:'
                        + ' Period - ' + this.planPeriod.name + ', Data Type - ' + this.selDataType.code
                        + ', Budget region - ' + form?.region_budget;
                }
                this.message(form, sms);
                return;
            }
            this.$set(form.header, 'dataType', versions[0].data_type);
            await this.saveForm(form, versions[0].variant_uuid, '', delet);
        } else { // если получатель имеется
            if (this.selVersion.variant_uuid === form.variant) {
                // console.log('сижу под Инициатором')
                if (!checkGu(this.userGus, form)) {
                    this.message(form, getSmsTitle(this, 'user_gu_match_initiator_gu'));
                    return;
                }

                if (form.id === 0 && this.selRegionBudget.code !== form.district_budget) {
                    this.message(form, getSmsTitle(this, 'recipient_gu_region_match_filter_region'));
                    return;
                }

                if (form.id > 0 && this.selRegionBudget.code !== form.region_budget) {
                    this.message(form, getSmsTitle(this, 'gu_region_match_filter_region'));
                    return;
                }

                if (checkGu(this.userGus, form) && this.selRegionBudget.code === form.region_budget) {
                    if (this.editSection4(form)) {
                        await forInitiator(this, 'form', form, this.versionList)
                            .then((data: any) => {
                                if (data.versions_rec && data.versions_rec.length > 0 && data.findDB === 0) {
                                    this.saveForm(form, form.id === 0 ? this.selVersion.variant_uuid : form.variant,
                                        data.versions_rec[0].variant_uuid, data.findDB === 0 && delet ? delet : false);
                                }
                            });
                        return;
                    } else {
                        if (this.selRegionBudget.code !== form.district_budget) {
                            this.message(form, getSmsTitle(this, 'recipient_gu_region_match_filter_region'));
                            return;
                        }
                    }
                }
            }

            if (this.selVersion.variant_uuid === form.variant_recipient) {
                // console.log('сижу под Получателем')
                if (!checkGuReg(this.userGus, form)) {
                    this.message(form, getSmsTitle(this, 'user_gu_match_recipient_gu'));
                    return;
                }

                if (this.selRegionBudget.code !== form.district_budget) {
                    this.message(form, getSmsTitle(this, 'gu_region_match_filter_region'));
                    return;
                }

                if (checkGuReg(this.userGus, form) && this.selRegionBudget.code === form.district_budget) {
                    await forRecipient(this, 'form', form, this.versionList)
                        .then((data: any) => {

                            if (data.versions && data.versions?.length > 0 && data.findDB === 0) {
                                this.saveForm(form, data.versions[0].variant_uuid, this.selVersion.variant_uuid,
                                    data.findDB === 0 && delet ? delet : false);
                            }
                        });
                    return;
                }
            }
        }
    }

    public async saveForm(form: BipProjectDataClass, variant: string, variant_rec: string, delet: boolean) {
        this.$set(form, 'isDeleted', delet);
        this.$set(form, 'versions', [form.variant, form.variant_recipient, variant,  variant_rec])

        const bip: any = form;
        delete bip.abp;
        delete bip.locality;
        delete bip.budget;
        delete bip.needs;
        delete bip.impact;
        delete bip.total;
        delete bip.status;
        delete bip.status_recipient;
        delete bip.name;
        delete bip.name_kk;
        delete bip.name_kz;
        delete bip.name_en;
        delete bip.name_ru;
        delete bip.nameCode;

        const params = JSON.stringify({
            variant: this.selVersion.variant_uuid,
            mode_code: this.mode_code,
            operation_code: this.operationCode
        });
        const newElem = {
            form: bip,
            variant: variant,
            variant_recipient: variant_rec,
            user_name: this.userUiid,
            params: params
        };
        const newId = form.id;

        try {
            const response = await fetch('/api-py/get-version/' + variant);
            if (!await response.json()) {
                makeToast(this, 'warning', getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'initiator_version_not_actual') + variant);
                this.saving = false;
                return;
            }
        } catch (error) {
            makeToast(this, 'danger', getSmsTitle(this, 'error_request') + ' /get-version', error.toString());
            return;
        }

        if (variant_rec.length > 0) {
            try {
                const response = await fetch('/api-py/get-version/' + variant_rec);
                if (!await response.json()) {
                    makeToast(this, 'warning', getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'recipient_version_not_actual') + variant_rec);
                    this.saving = false;
                    return;
                }
            } catch (error) {
                makeToast(this, 'danger', getSmsTitle(this, 'error_request') + ' /get-version', error.toString());
                return;
            }
        }

        await postData('/api-py/save-bip-form', newElem).then((data) => {
            if (data.result) {
                if (newId === 0) {
                    this.$set(form, 'id', data.id);
                    this.$set(form, 'code', data.code);
                } else {
                    let i = 0;
                    for (const bip of this.bipList) {
                        if (bip.id === newId) {
                            this.bipList.splice(i, 1);
                            break;
                        }
                        i++;
                    }
                }
                if (delet) {
                    makeToast(this, 'success', getSmsTitle(this, 'removal'),
                        getSmsTitle(this, 'post_deleted'));
                } else {
                    this.bipProjectData = BipProjectDataClass.assign(data.bip);
                    defineBip(this, this.bipProjectData, 'form');

                    setTimeout(() => {
                        this.bipList.unshift(this.bipProjectData);
                        this.loadBipAgreement(data.code);

                        if (this.showForm) {
                            this.showForm = false;
                            for (const bip of this.bipList) {
                                if (this.bipProjectData && bip.id === this.bipProjectData.id) {
                                    this.openBip(this.bipProjectData, false);
                                    this.bipForm.equal = true;
                                    break;
                                }
                            }
                        }
                    }, 1000);

                    this.saveFinace(form, variant, variant_rec);
                    makeToast(this, 'success', getSmsTitle(this, 'saving'),
                        getSmsTitle(this, 'datas_saved'));
                }
            } else {
                makeToast(this, 'danger', getSmsTitle(this, 'error_save'), data.error);
            }
        });
        this.saving = false;
    }

    private async saveFinace(form: BipProjectDataClass, variant: any, variant_rec: any) {
        const values = [];
        if (variant.length > 0) {
            const item = {};
            this.$set(item, 'isDeleted', form?.isDeleted);
            this.$set(item, 'bip_code', form?.code);
            this.$set(item, 'object', form?.object);
            this.$set(item, 'region', form?.region_budget);
            this.$set(item, 'data_type', form.header.dataType);
            this.$set(item, 'abp', form?.header?.abp);
            this.$set(item, 'gu', form?.header?.gu?.code);
            this.$set(item, 'cur_year', form?.header?.year);
            this.$set(item, 'variant', variant);

            const arr = [];
            for (const obj of form?.newFinace) {
                for (const [key, value] of Object.entries(obj)) {
                    if (parseInt(key) >= parseInt(form?.header?.year)
                        && parseInt(key) <= parseInt(form?.header?.year) + 2) {
                        const val = {
                            gr: obj?.gr,
                            prg: obj?.prg,
                            ppr: obj?.ppr,
                            spf: obj?.spf,
                            year: key,
                            value: value
                        };
                        arr.push(val);
                    }
                }
            }
            for (const row of arr) {
                const newRow = Object.assign({}, item);
                this.$set(newRow, 'gr', row?.gr);
                this.$set(newRow, 'prg', row?.prg);
                this.$set(newRow, 'ppr', row?.ppr);
                this.$set(newRow, 'spf', row?.spf);
                this.$set(newRow, 'year', row?.year);
                this.$set(newRow, 'value', row?.value);
                values.push(newRow);
            }
        }

        // Районный (конечный)
        if (form.header?.finalAbp === 0 && form?.newFinaceRegional && variant_rec.length > 0) {
            const item = {};
            this.$set(item, 'isDeleted', form?.isDeleted);
            this.$set(item, 'bip_code', form?.code);
            this.$set(item, 'object', form?.object);
            this.$set(item, 'region', form?.district_budget);
            this.$set(item, 'data_type', form.header?.regional.dataType);
            this.$set(item, 'abp', form?.header?.regional.abp);
            this.$set(item, 'gu', form?.header?.regional.gu?.code);
            this.$set(item, 'cur_year', form?.header?.year);
            this.$set(item, 'variant', variant_rec);

            const arr = [];
            for (const obj of form?.newFinaceRegional) {
                for (const [key, value] of Object.entries(obj)) {
                    if (parseInt(key) >= parseInt(form?.header?.year)
                        && parseInt(key) <= parseInt(form?.header?.year) + 2) {
                        const val = {
                            gr: obj?.gr,
                            prg: obj?.prg,
                            ppr: obj?.ppr,
                            spf: obj?.spf,
                            year: key,
                            value: value
                        };
                        arr.push(val);
                    }
                }
            }

            for (const row of arr) {
                const newRow = Object.assign({}, item);
                this.$set(newRow, 'gr', row?.gr);
                this.$set(newRow, 'prg', row?.prg);
                this.$set(newRow, 'ppr', row?.ppr);
                this.$set(newRow, 'spf', row?.spf);
                this.$set(newRow, 'year', row?.year);
                this.$set(newRow, 'value', row?.value);
                values.push(newRow);
            }
        }

        const newElem = {
            'bip_code': form?.code,
            'cur_year': form?.header?.year,
            'data_type': form?.header?.dataType,
            'user_name': this.userUiid,
            'variant': variant,
            'variant_recipient': variant_rec,
            'versions': form?.versions,
            'values': values
        }

        try {
            const response = await fetch('/api-py/save-bip-budget-request-total/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(newElem)
            });
            const result = await response.json();
            if (response.status === 200 && result.result === 'success') {
                makeToast(this, 'success', getSmsTitle(this, 'saving'),
                    getSmsTitle(this, 'datas_saved'));
                return true;
            }
        } catch {
            makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                getSmsTitle(this, 'error_save'));
            return false;
        }
    }

    private async scrollMeToList() {
        this.$root.$emit('removeClass');
        this.backList = true;
        setTimeout(() => {
            if (this.editAccess && !this.bipForm.equal) {
                this.$bvModal.msgBoxConfirm(
                    getSmsTitle(this, 'save_changes'),
                    {
                        title: getSmsTitle(this, 'confirmation'),
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: getBipTitle(this, 'btn.ok'),
                        cancelTitle: getBipTitle(this, 'btn.cancel'),
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        modalClass: 'del-item-modal-script'
                    }).then(value => {
                    if (value) {
                        if (!this.bipForm.save) {
                            this.openBip(this.bipForm.form, false);
                            return;
                        }
                        this.saveBip(this.bipForm.form, this.bipForm.equal, true, false);
                    } else {
                        this.bipForm.equal = true;
                    }
                }).catch(error => {
                    makeToast(this, 'danger', getSmsTitle(this, 'error_delete'), error.toString());
                });
            }
            const el = this.$refs.bipList;
            el.scrollIntoView({behavior: 'smooth'});
            this.showForm = false;
        }, 1000);
    } // кнопка назад к списку

    private async scrollMeToListModeling() {
        this.$root.$emit('bipModel', true);
        this.$root.$emit('removeClass', true);
        this.backList = true;
        setTimeout(() => {
            if (this.editAccess && !this.bipForm.equal) {
                this.$bvModal.msgBoxConfirm(
                    getSmsTitle(this, 'save_changes'),
                    {
                        title: getSmsTitle(this, 'confirmation'),
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: getBipTitle(this, 'btn.ok'),
                        cancelTitle: getBipTitle(this, 'btn.cancel'),
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        modalClass: 'del-item-modal-script'
                    }).then(value => {
                    if (value) {
                        if (!this.bipForm.save) {
                            this.openBip(this.bipForm.form, false);
                            return;
                        }
                        this.saveBip(this.bipForm.form, this.bipForm.equal, true, false);
                    } else {
                        this.bipForm.equal = true;
                    }
                }).catch(error => {
                    makeToast(this, 'danger', getSmsTitle(this, 'error_delete'), error.toString());
                });
            }
            const el = this.$refs.bipList;
            el.scrollIntoView({behavior: 'smooth'});
            this.showForm = false;
        }, 1000);
    } // кнопка назад к списку

    private updateAgreement() {
        if (this.agrMap) {
            for (const code of this.agrMap.keys()) {
                const bip = this.bipList.filter(row => row.code === code);
                if (bip.length > 0) {
                    const item = bip[0];
                    this.$set(item['addDatas'], 'status', this.agrMap.get(code));
                }
            }
        }
    }

    private async updateBip(item: any) {
        try {
            if (item.form.id === 0 || item.form.header.edited) {
                setVariants(this, item.form);
            }
            if (this.editAccess && this.saving && item.form && item.save) {
                await this.saveBip(item.form, item.equal, item.save, item.delete);
            }
        } catch (error) {
            console.log('error updateBip => ', item, error.toString());
        } finally {
            this.saving = false;
            this.bipForm = item;
        }
    }

    private getCommonTitle(field: string) {
        return getCommonTitle(this, field);
    }

    private getBipTitle(field: string) {
        return getBipTitle(this, field);
    }

    private getFilterTitle(field: string) {
        return getFilterTitle(this, field);
    }

    private getTFieldTitle(field: string) {
        return getTFieldTitle(this, field);
    }

    private getCardTitle(sector: string, field: string) {
        return getCardTitle(this, sector, field);
    }
}
